import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';

import Layout from '../components/Layout';
import { formatMarkdownData } from '../data/_helper';
import Footer from '../components/Footer';

interface PrivacyPageProps {
  slider: SliderProps;
  page: {
    html: string;
  };
  footer_section: FooterProps;
}
const Privacy = ({ footer_section, page, slider }: PrivacyPageProps) => {
  return (
    <Layout>
      <header className="header py-2" id="home">
        <div className="mask" />
        <div className="container">
          <figure className="logo animated fadeInDown">
            <Link to="/">
              <img src={slider.sliderLogo} alt={slider.sliderLogoText} />
            </Link>
          </figure>
        </div>
      </header>
      <div className="container py-5 privacy" dangerouslySetInnerHTML={{ __html: page.html }} />
      <Footer data={footer_section} />
    </Layout>
  );
};
const PrivacyPage = () => (
  <StaticQuery
    query={graphql`
      query PrivacyPageQuery {
        allMarkdownRemark(limit: 1, filter: { frontmatter: { page: { eq: "index" } } }) {
          edges {
            node {
              id
              frontmatter {
                slider {
                  sliderLogo
                  sliderLogoText
                }
                footer_section {
                  privacy
                  copyright
                  logo {
                    file
                    text
                  }
                }
              }
            }
          }
        }
        page: allMarkdownRemark(limit: 1, filter: { frontmatter: { section: { eq: "privacy" } } }) {
          edges {
            node {
              html
            }
          }
        }
      }
    `}
    render={data => {
      const FormData = formatMarkdownData<PrivacyPageProps>(data)[0];
      return (
        <Privacy slider={FormData.slider} page={data.page.edges[0].node} footer_section={FormData.footer_section} />
      );
    }}
  />
);

export default PrivacyPage;
